import { axiosInstance } from "../config";

export default {
  state: {
    history_show: [],
    history_download: [],
  },
  mutations: {
    SetRatesHistoryShow(state, payload) {
      state.history_show = payload;
    },
    SetRatesHistoryDownload(state, payload) {
      state.history_download = payload;
    },
  },
  actions: {
    async Get_Exchange_Rates_History({ commit }, payload) {
      return await axiosInstance
        .get(
          `/exchangeRates/history/${payload.dateFrom}${
            payload.dateFrom == payload.dateTo ? "" : "/"+payload.dateTo
          }`
        )
        .then(function (data) {
          if (payload.type == "show") {
            commit("SetRatesHistoryShow", data.data?.data);
          } else {
            commit("SetRatesHistoryDownload", data.data?.data);
          }
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
  },
  getters: {
    getRatesHistoryShow: (state) => state.history_show,
    getRatesHistoryDownload: (state) => state.history_download,
  },
};
