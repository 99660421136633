import axios from "axios";

let urlAPI = "https://apibreeze.amark.pro/";
let axiosInstance = axios.create({
  baseURL: urlAPI,
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
  },
});

export { urlAPI, axiosInstance };
