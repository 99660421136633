import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
/* import { loadFonts } from "./plugins/webfontloader"; */
import { createMetaManager, plugin as metaPlugin } from "vue-meta";
/* import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css"; */
/* import VueGtag from "vue-gtag"; */
import "./assets/style.scss"

/* loadFonts(); */

const appVue = createApp(App);
/* appVue.component("vue-cookie-accept-decline", VueCookieAcceptDecline); */

appVue
  /* .use(
    VueGtag,
    {
      config: { id: "" },
      appName: "",
      pageTrackerScreenviewEnabled: true,
      enabled: false,
      onError(error) {
        console.log(error);
      },
    },
    router
  ) */
  .use(router)
  .use(store)
  .use(vuetify)
  .use(createMetaManager())
  .use(metaPlugin);

appVue.mount("#app");
