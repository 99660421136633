import { axiosInstance } from "../config";

export default {
  state: {
    rates: [],
    rates_all: [],
  },
  mutations: {
    SetRates(state, payload) {
      state.rates = payload;
    },
    SetRatesAll(state, payload) {
      state.rates_all = payload;
    },
  },
  actions: {
    async Get_Exchange_Rates({ commit }) {
      return await axiosInstance
        .get("/exchangeRates")
        .then(function (data) {
          commit("SetRates", data.data?.data);
          return data.data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Get_Exchange_Rates_All({ commit }) {
      return await axiosInstance
        .get("/exchangeRates/all")
        .then(function (data) {
          let data_modified = data.data?.data
            .map((item) => {
              return { ...item, hidden: Boolean(!item.hidden) };
            })
            .sort((a, b) => a.exchange_id - b.exchange_id);
          commit("SetRatesAll", data_modified);
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Put_Exchange_Rates_Publish({}, payload) {
      let data = {};
      if (payload.doMailingList == true) {
        data.doMailingList = true;
      }
      return await axiosInstance
        .put("/exchangeRates/publish", data)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Patch_Exchange_Rates_StoreDraft({}, payload) {
      let id = payload.exchange_id;
      if (!id) return;
      let data = {
        sell_price_day: Number(payload.draft_sell_price_day),
        sell_price_night: Number(payload.draft_sell_price_night),
        buy_price_day: Number(payload.draft_buy_price_day),
        buy_price_night: Number(payload.draft_buy_price_night),
      };
      return await axiosInstance
        .patch(`/exchangeRates/storeDraft/${id}`, data)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Patch_Exchange_Rates_Toggle_Hidden({}, payload) {
      let id = payload.id;
      if (!id) return;
      return await axiosInstance
        .patch(`/exchangeRates/toggle_hidden/${id}`, payload)
        .then(function () {
          return {
            success: true,
          };
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
  },
  getters: {
    getRates: (state) => state.rates,
    getRatesAll: (state) => state.rates_all,
  },
};
