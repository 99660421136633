import { axiosInstance } from "../config";

export default {
  state: {
    currencys: [],
    currencys_all: [],
    currency_flags: [],
  },
  mutations: {
    SetCurrencys(state, payload) {
      state.currencys = payload;
    },
    SetCurrencysAll(state, payload) {
      state.currencys_all = payload;
    },
    SetCurrencyFlags(state, payload) {
      state.currency_flags = payload;
    },
  },
  actions: {
    async Get_Currencys({ commit }) {
      return await axiosInstance
        .get("/currency")
        .then(function (data) {
          commit("SetCurrencys", data.data);
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Get_Currencys_All({ commit }) {
      return await axiosInstance
        .get("/currency/all")
        .then(function (data) {
          let data_modified = data.data
            .map((item) => {
              return { ...item, is_archived: Boolean(item.is_archived) };
            })
            .sort((a, b) => a.id - b.id);
          commit("SetCurrencysAll", data_modified);
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Post_Currency({}, payload) {
      return await axiosInstance
        .post("/currency", payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Put_Currency({}, payload) {
      let id = payload.id;
      if (!id) return;
      let data = {
        name_ru: payload.name_ru,
        name_ro: payload.name_ro,
        short_name: payload.short_name,
        currency_icon_id: payload.currency_icon_id,
      };
      return await axiosInstance
        .put(`/currency/${id}`, data)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Toggle_Currency({}, payload) {
      let id = payload.id;
      if (!id) return;
      return await axiosInstance
        .patch(`/currency/toggle_archived/${id}`)
        .then(function () {
          return {
            success: true,
          };
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Get_Currency_Flags({ commit }) {
      return await axiosInstance
        .get("/currency/icons")
        .then(function (data) {
          commit("SetCurrencyFlags", data.data);
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
  },
  getters: {
    getCurrencys: (state) => state.currencys,
    getCurrencysAll: (state) => state.currencys_all,
    getCurrencyFlags: (state) => state.currency_flags,
  },
};
