import { axiosInstance } from "../config";

export default {
  state: {
    token: window.localStorage.getItem("token"),
  },
  mutations: {
    SetToken(state, payload) {
      state.token = payload;
    },
  },
  actions: {
    async AdminCkeck() {
      if (
        !window.localStorage.getItem("token") ||
        window.localStorage.getItem("token") == "undefined"
      ) {
        return;
      } else {
        return true;
      }
    },
    async Logout({ commit }) {
      return await axiosInstance
        .post("/user/logout")
        .then(function (data) {
          window.localStorage.removeItem("token");
          commit("SetToken", "");
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async LogoutTerminate({ commit }) {
      return await axiosInstance
        .post("/user/terminate")
        .then(function (data) {
          window.localStorage.removeItem("token");
          commit("SetToken", "");
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async SignIn({ commit }, payload) {
      let body = {
        name: payload.username,
        password: payload.password,
        password_confirmation: payload.password,
      };
      let data = await axiosInstance
        .post("/user/login", body)
        .catch(function (error) {
          return {
            data: {
              data: {
                error: error.response?.data?.message,
              },
            },
          };
        });
      if (data?.data?.success == true) {
        window.localStorage.setItem("token", data?.data?.data.token);
        commit("SetToken", data?.data?.data.token);
        return {
          success: true,
        };
      } else {
        return data?.data?.data;
      }
    },
    async ChangePassword({}, payload) {
      return await axiosInstance
        .patch("/user/change_password", payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
  },
  getters: {
    getToken: (state) => state.token,
  },
};
