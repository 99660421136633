import { createStore } from 'vuex'
import CRM_Auth from "./CRM/Auth";
import CRM_Currency from "./CRM/Currency";
import CRM_Rates from "./CRM/Rates";
import CRM_RatesHistory from "./CRM/RatesHistory";
import CRM_Subscriptions from "./CRM/Subscriptions";
import CRM_VipRequests from "./CRM/VipRequests";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    CRM_Auth,
    CRM_Currency,
    CRM_Rates,
    CRM_RatesHistory,
    CRM_Subscriptions,
    CRM_VipRequests,
  }
})
