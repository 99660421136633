import { axiosInstance } from "../config";

export default {
  state: {
    subscriptions: [],
  },
  mutations: {
    SetSubscriptions(state, payload) {
      state.subscriptions = payload;
    },
  },
  actions: {
    async Get_Subscriptions({ commit }) {
      return await axiosInstance
        .get(`/subscriptions`)
        .then(function (data) {
          let data_modified = data.data.data.sort((a, b) => b.id - a.id);
          commit("SetSubscriptions", data_modified);
          return data_modified;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Post_Subscription({}, payload) {
      return await axiosInstance
        .post(`/subscriptions`, payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Put_Subscription({}, payload) {
      let data = {
        email: payload.email,
      };
      return await axiosInstance
        .put(`/subscriptions/${payload.id}`, data)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Delete_Subscription_Destroy({}, payload) {
      return await axiosInstance
        .delete(`/subscriptions/${payload.id}`)
        .then(function () {
          return {
            success: true,
          };
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Delete_Subscription_Unsubscribe({}, payload) {
      return await axiosInstance
        .delete(`/subscriptions/${payload.id}/${payload.email}`)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
  },
  getters: {
    getSubscriptions: (state) => state.subscriptions,
  },
};
