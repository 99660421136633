import ro from "./ro.js";
import ru from "./ru.js";

let lang = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "ru";

export const locale = {
  locale: lang,
  fallback: "ru",
  globalInjection: true,
  messages: { ro, ru },
};
