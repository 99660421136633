import { ru } from "vuetify/lib/locale";

export default {
  $vuetify: { ...ru },
  ...ru,
  home: {
    header: {
      curs_currency: "Курс Валют",
      location: "Локации",
      subscription: "Подписка",
      vip_clients: "VIP клиенты",
      contacts: "Контакты",
      book_call: "Позвонить",
    },
    firstBlock: {
      title: "Обмен валют в центре Кишинева",
      subtitle: "Ștefan cel Mare și Sfînt 136, Кишинев. Торговый центр Gemini",
      btn_location: "Локации обменных пунктов",
    },
    Rate: {
      curs_valutar: "Курс валют от",
      curs_zilnic: "Дневной курс от",
      curs_noapte: "Ночной курс от",
      convertor: "Конвертер валют",
      rata_zilnic: "Счет по дневному курсу от",
      rata_noapte: "Счет по ночному курсу от",
      summa: "Сумма",
      vind: "Продажа",
      cumpar: "Покупка",
    },
    RowVip: {
      tag_clients: "+300 клиентов",
      tag_cassa: "VIP касса",
      title: "Комфортный и безопасный обмен крупных сумм",
      text: "Оставьте заявку ниже о сумме которую желаете поменять и мы свяжемся с Вами.",
      btn_cerere: "Оставить заявку",
    },
    HowItWork: {
      block_title: "Как это работает?",
      title_1: "Оставьте заявку на обмен нужной суммы",
      text_1: "Мы получаем вашу заявку и обрабатываем в кратчайшие сроки.",
      title_2: "Получите звонок с подробной информацией",
      text_2:
        "После обработки заявки, мы перезвоним Вам и сообщим детали и время обмена.",
      title_3: "Мы выполним Ваш запрос в VIP кассе в назначенное время",
      text_3:
        "Комфортный обмен указанной суммы по вашему запросу в отдельной кассе.",
    },
    BenefitsVip: {
      block_title: "Удобства VIP обслуживания",
      title_1: "Экономия времени",
      text_1: "Заказанная валюта будет готова к выдаче.",
      title_2: "Удобство для крупных сумм",
      text_2: "Обмен больших сумм без риска отсутствия валюты.",
      title_3: "Индивидуальный подход",
      text_3: "Забота о клиенте, повышающая доверие.",
    },
    ContactUs: {
      block_title: "Оставь заявку на обмен крупной суммы",
      name: "Имя и Фамилия",
      phone: "Телефон",
      count: "В сумме:",
      type_label: "Я хочу:",
      type_buy: "Купить",
      type_sell: "Продать",
      valuta: "В валюте",
      coment: "Комментарий",
      placeholder:
        "Привет! Я хочу купить 5 500 евро сегодня через VIP окно в кассе Money Breeze.",
      send: "ОТПРАВИТЬ ЗАПРОС",
      text_bottom:
        "Нажимая «отправить сообщение», вы соглашаетесь с тем, что ваши персональные данные будут обработаны MONEY BREEZE. Мы сохраняем конфиденциальность вашей информации и личных данных.",
      dialog_modal: {
        title: "Запрос отправлен!",
        text: "Ваш запрос на обмен указанной суммы был отправлен! Мы перевзоним Вам в ближайшее время!",
        btn_text: "Хорошо",
      },
    },
    RowSubscribe: {
      tag_1: "Ежедневно",
      tag_2: "На Почту",
      block_title: "Хочешь всегда иметь курсы валют под рукой?",
      block_text:
        "Подпишись на рассылку MoneyBreeze и получай наши курсы валют каждый день на электронную почту.",
      btn_subscribe: "Подписаться",
    },
    Subscription: {
      block_title: "Как это работает?",
      step_1_title: "Введите актуальный адрес вашей почты",
      step_1_text: "Оставьте рабочую почту ниже ",
      step_2_title: "Ожидайте публикацию курса",
      step_2_text: "Мы публикуем курс два раза в день: дневной и вечерний",
      step_3_title: "Получайте курс валют ежедневно",
      step_3_text:
        "Каждый день вы будете получать курс MoneyBreeze в удобном формате",
      step_4_title: "Введите вашу почту",
      step_4_btn: "ОТПРАВИТЬ ЗАПРОС",
      step_4_text:
        "Нажимая «отправить сообщение», вы соглашаетесь с тем, что ваши персональные данные будут обработаны MONEY BREEZE. Мы сохраняем конфиденциальность вашей информации и личных данных. Вы всегда можете отписаться от рассылки.",
      dialog_modal: {
        title: "Запрос отправлен!",
        text: "Вы подписались на обновления",
        btn_text: "Хорошо",
      },
    },
    Locations: {
      block_title: "Локация обменников MoneyBreeze",
      block_1: {
        title_1: "Торговый центр 'Gemini'",
        text_1:
          "Обменка в центре Кишинева. Торговый центр Gemini, вход со стороны Stefan Cel Mare.",
        title_2: "VIP обслуживание",
        text_2_1:
          "В данном обменном пункте мы обслуживаем VIP клиентов по предварительному запросу.",
        text_2_2: "Узнать больше",
        title_3: "График работы",
        text_3: {
          p1: "Понедельник - Пятница 09:00 - 20:00",
          p2: "Суббота - Воскресение 10:00 - 19:00",
        },
      },
      block_2: {
        title_1: "Скоро и в других районах Кишинева",
        text_1: "Другие обменные пункты Money Breeze",
      },
    },
    Contacts: {
      block_title: "Контакты",
      email: "Email",
      phone: "Телефон",
      program: "График",
      program_text: {
        p1: "Понедельник - Пятница 09:00 - 20:00",
        p2: "Суббота - Воскресение 10:00 - 19:00",
      },
      location: "Локация",
      location_text:
        "Bulevardul Ștefan cel Mare și Sfînt 136, MD-2012, Chișinău",
      text_bottom: "аккредитовано Национальным Банком Республики Молдовы.",
    },
  },
};
