// Styles
/* import "@mdi/font/css/materialdesignicons.css"; */
import "vuetify/styles";
import { locale } from "../locales/index.js";
import { VDateInput } from "vuetify/labs/VDateInput";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  components: {
    VDateInput,
  },
  locale,
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
});
