import { axiosInstance } from "../config";

export default {
  state: {
    vipRequests: [],
  },
  mutations: {
    SetVipRequests(state, payload) {
      state.vipRequests = payload;
    },
  },
  actions: {
    async Get_VipRequests({ commit }) {
      return await axiosInstance
        .get(`/vipRequests`)
        .then(function (data) {
          commit("SetVipRequests", data.data?.data);
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Post_VipRequests({}, payload) {
      return await axiosInstance
        .post(`/vipRequests`, payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Delete_VipRequests({}, payload) {
      return await axiosInstance
        .delete(`/vipRequests/${payload.id}`)
        .then(function () {
          return {
            success: true
          }
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
    async Patch_VipRequests_Toggle({}, payload) {
      return await axiosInstance
        .patch(`/vipRequests/toggle_finished/${payload.id}`)
        .then(function () {
          return {
            success: true,
          }
        })
        .catch(function (error) {
          return {
            error: error.response?.data?.message,
          };
        });
    },
  },
  getters: {
    getVipRequests: (state) => state.vipRequests,
  },
};
