import { ro } from "vuetify/lib/locale";

export default {
  $vuetify: { ...ro },
  ...ro,
  home: {
    header: {
      curs_currency: "Curs valutar",
      location: "Locații",
      subscription: "Abonament",
      vip_clients: "Schimb VIP",
      contacts: "Contacte",
      book_call: "Rezervați un apel",
    },
    firstBlock: {
      title: "Schimb valutar în centrul Chișinăului",
      subtitle:
        "Ștefan cel Mare și Sfînt 136, Chișinău. Centrul Comercial Gemini",
      btn_location: "Locații ale punctelor de schimb",
    },
    Rate: {
      curs_valutar: "Curs valutar din",
      curs_zilnic: "Curs zilnic din",
      curs_noapte: "Curs de seară din",
      convertor: "Convertor valutar",
      rata_zilnic: "Rată la cursul zilnic",
      rata_noapte: "Rată la cursul de seară",
      summa: "Sumă",
      vind: "Vânzare",
      cumpar: "Cumpărare",
    },
    RowVip: {
      tag_clients: "+300 clienți",
      tag_cassa: "Casă VIP",
      title: "Schimb confortabil și sigur de sume mari",
      text: "Lăsați o cerere mai jos cu suma pe care doriți să o schimbați și vom lua legătura cu dvs.",
      btn_cerere: "Lasă o cerere",
    },
    HowItWork: {
      block_title: "Cum funcționează?",
      title_1: "Lăsați o cerere pentru suma dorită",
      text_1: "Procesăm cererea dvs. cât mai repede posibil.",
      title_2: "Primiți un apel cu informații detaliate",
      text_2:
        "După ce procesăm cererea, vă contactăm pentru a vă oferi detalii și ora schimbului.",
      title_3: "Vom onora cererea dvs. la casa VIP la ora stabilită",
      text_3:
        "Schimb confortabil al sumei specificate, la cererea dvs., într-o casă separată.",
    },
    BenefitsVip: {
      block_title: "Beneficiile serviciului VIP",
      title_1: "Economisire de timp",
      text_1: "Suma comandată în moneda necesară va fi gata de emitere.",
      title_2: "Comoditate pentru sume mari",
      text_2: "Schimb de sume mari fără riscul lipsei monedei.",
      title_3: "Abordare individuală",
      text_3: "Grija față de client, creșterea încrederii.",
    },
    ContactUs: {
      block_title: "Lasă o cerere pentru schimbul unei sume mari",
      name: "Nume și Prenume",
      phone: "Telefon",
      count: "În suma:",
      type_label: "Eu vreau:",
      type_buy: "sa Cumpar",
      type_sell: "sa Vând",
      valuta: "În valută",
      coment: "Comentariu",
      placeholder:
        "Bună ziua, vreau să cumpăr 5.500 € astăzi prin fereastra VIP de la casieria Money Breeze.",
      send: "TRIMITE CEREREA",
      text_bottom:
        "Apăsând „Trimite cererea”, sunteți de acord ca datele dvs. personale să fie prelucrate de MONEY BREEZE. Respectăm confidențialitatea informațiilor și a datelor dvs. personale.",
      dialog_modal: {
        title: "Solicitare trimisă!",
        text: "Solicitarea dvs. de a schimba suma specificată a fost trimisă! Vă vom suna înapoi în curând!",
        btn_text: "Bine",
      },
    },
    RowSubscribe: {
      tag_1: "Abonare zilnică",
      tag_2: "La Email",
      block_title: "Dorești să ai mereu la îndemână cursurile valutare?",
      block_text:
        "Abonează-te la newsletter-ul MoneyBreeze și primește cursurile valutare zilnic pe email.",
      btn_subscribe: "Abonare",
    },
    Subscription: {
      block_title: "Cum funcționează?",
      step_1_title: "Introduceți adresa de email actuală",
      step_1_text: "Lăsați mai jos emailul de lucru",
      step_2_title: "Așteptați publicarea cursului",
      step_2_text: "Publicăm cursul de două ori pe zi: dimineața și seara",
      step_3_title: "Primiți cursul valutar zilnic",
      step_3_text:
        "În fiecare zi veți primi cursul MoneyBreeze într-un format comod",
      step_4_title: "Introduceți adresa de email",
      step_4_btn: "TRIMITE CEREREA",
      step_4_text:
        "Apăsând „Trimite cererea”, sunteți de acord ca datele dvs. personale să fie prelucrate de MONEY BREEZE. Respectăm confidențialitatea informațiilor și a datelor dvs. personale. Vă puteți dezabona oricând.",
      dialog_modal: {
        title: "Solicitare trimisă!",
        text: "Te-ai abonat la cursului de schimb pe email!",
        btn_text: "Bine",
      },
    },
    Locations: {
      block_title: "Locația punctelor de schimb MoneyBreeze",
      block_1: {
        title_1: "Centrul Comercial „Gemini”",
        text_1:
          "Casa de schimb în centrul Chișinăului. Centrul Comercial Gemini, intrarea dinspre Stefan cel Mare.",
        title_2: "Serviciu VIP",
        text_2_1:
          "În acest punct de schimb, deservim clienți VIP pe bază de cerere prealabilă.",
        text_2_2: "Află mai multe",
        title_3: "Program de lucru",
        text_3: {
          p1: "Luni - Vineri 09:00 - 20:00",
          p2: "Sâmbăta - Duminica 10:00 - 19:00",
        },
      },
      block_2: {
        title_1: "În curând în alte zone ale Chișinăului",
        text_1: "Alte puncte de schimb Money Breeze",
      },
    },
    Contacts: {
      block_title: "Contacte",
      email: "Email",
      phone: "Telefon",
      program: "Program",
      program_text: {
        p1: "Luni - Vineri 09:00 - 20:00",
        p2: "Sâmbăta - Duminica 10:00 - 19:00",
      },
      location: "Locație",
      location_text:
        "Bulevardul Ștefan cel Mare și Sfînt 136, MD-2012, Chișinău",
      text_bottom: "Acreditat de Banca Națională a Republicii Moldova.",
    },
  },
};
